<template>
  <el-tooltip :content="content" placement="bottom" effect="dark" popper-class="tool-tip" :visible="visible">
    <div ref="tooltip" class="text" @mouseover="over" @mouseleave="leave">
      <template v-if="subsection">
        <p v-for="i in content.split(',')">
          {{ i || '' }}
        </p>
      </template>
      <div v-else>{{ content }}</div>
    </div>
  </el-tooltip>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  content: String,
  subsection: {
    type: Boolean,
    default: false,
  },
})

const tooltip = ref()
const times = ref()
const visible = ref(false)
const over = () => {
  clearTimeout(times.value)
  times.value = setTimeout(() => {
    clearTimeout(times.value)
    visible.value = tooltip.value.scrollHeight > tooltip.value.offsetHeight
  }, 600)
}
const leave = () => {
    clearTimeout(times.value)
  visible.value = false
}
</script>
