import { createApp } from 'vue'
import JzCopy from './CopyButton.vue'
import JzInfo from './InfoButton.vue'
import JzTooltip from './Tooltip.vue'
const app = createApp()

export default function (app) {
  app.component('jz-tooltip', JzTooltip)
  app.component('jz-info', JzInfo)
  app.component('jz-copy', JzCopy)
}
