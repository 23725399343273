<template>
  <div class="modal">
    <div class="box">
      <img class="img" :src="url" v-if="url && url.includes('http')" />
      <template v-else>
        <vue-qr :text="url" :size="250" :callback="qrBack" />
        <p class="text">{{ url }}</p>
        <div @click="downLoad(url)" class="dialog-btn">下载</div>
      </template>
      <div @click="emit('click')" class="dialog-btn btn2">关闭</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import VueQr from 'vue-qr/src/packages/vue-qr.vue'
const emit = defineEmits(['click'])
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
})

const qrData = ref(null)
const qrBack = (dataUrl, id) => {
  qrData.value = dataUrl
}
function downLoad(code) {
  setTimeout(() => {
    let a = document.createElement('a')
    a.style.display = 'none'
    a.download = code
    a.href = qrData.value
    document.body.appendChild(a)
    a.click()
  }, 500)
}
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  z-index: 2124;
}
.box {
  position: fixed;
  top: 0;
  /* right: 0;
    bottom: 0; */
  left: 0;
  overflow: auto;
  box-shadow: 0px 0px 14px 0px rgba(38, 38, 38, 0.5);
  border-radius: 12px;
  width: 500px;
  /* min-height: 200px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #fff;
  text-align: center;
}
.img {
  padding: 20px 0;
  /* margin: 50px 0; */
  max-height: 80vh;
  object-fit: contain;
  margin: 0;
}
.text {
  word-wrap: break-word;
  padding: 0 32px;
}
.dialog-btn {
  color: #fff !important;
  background: #2c82f8 !important;
  border: 1px solid #2c82f8 !important;
  padding: 8px 16px;
  border-radius: 2px;
  cursor: pointer;
  width: 64px;
  margin: 10px;
  display: inline-block;
}
.dialog-btn:active {
  opacity: 0.5;
}
.btn2 {
  color: #000 !important;
  background: #fff !important;
  border: 1px solid #eee !important;
}

.btn2:active {
  color: #fff !important;
  background: #606266 !important;
  border: 1px solid #606266 !important;
  cursor: wait;
}
</style>
