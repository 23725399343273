const errorRouter = {
  title: '404',
  type: 'view',
  name: Symbol('404'),
  route: '/404',
  filePath: 'view/error-page/404.vue',
  inNav: false,
  icon: 'iconfont icon-rizhiguanli',
  sortId: 1,
}
export default errorRouter
