import { createApp } from 'vue'
import MessageBox from './MessageBox.vue'
import ImgBox from './ImgBox.vue'

export function showMsg(msg, handleClick, handleDelect) {
  const app = createApp(MessageBox, { msg, onClick: handleClick, onDelect: handleDelect })
  const div = document.createElement('div')
  document.body.appendChild(div)
  app.mount(div)
  return () => {
    app.unmount()
    document.body.removeChild(div)
  }
}

export function showImg(url, handleClick) {
  const app = createApp(ImgBox, { url, onClick: handleClick })
  const div = document.createElement('div')
  document.body.appendChild(div)
  app.mount(div)
  return () => {
    app.unmount()
    document.body.removeChild(div)
  }
}
