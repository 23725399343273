const personalRouter = {
  title: '个人中心',
  type: 'view',
  name: Symbol('center'),
  route: '/center',
  filePath: 'view/center/center.vue',
  inNav: false,
  icon: 'iconfont icon-rizhiguanli',
}
export default personalRouter
