const homeRouter = {
  title: '极至科技',
  type: 'view',
  name: Symbol('about'),
  route: '/about',
  filePath: 'view/about/about.vue',
  inNav: true,
  icon: 'iconfont icon-iconset0103',
  sortId: 1,
}
export default homeRouter
