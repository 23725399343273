import { ElMessage, messageConfig } from 'element-plus'
export function copyFun(str) {
  var _input = document.createElement('input') // 直接构建input
  _input.value = str // 设置内容
  document.body.appendChild(_input) // 添加临时实例
  _input.select() // 选择实例内容
  document.execCommand('Copy') // 执行复制
  document.body.removeChild(_input) // 删除临时实例

  ElMessage({
    message: str+' 复制成功',
    type: 'success',
    showClose: true,
    duration: 3000,
    grouping: true,
  })
}
export function copyFun2(str) {
  var _input = document.createElement('textarea') // 直接构建input
  _input.value = str // 设置内容
  document.body.appendChild(_input) // 添加临时实例
  _input.select() // 选择实例内容
  document.execCommand('Copy') // 执行复制
  document.body.removeChild(_input) // 删除临时实例

  ElMessage({
    message: '复制成功',
    type: 'success',
    showClose: true,
    duration: 3000,
    grouping: true,
  })
}
export default {
  copyFun, copyFun2
}
